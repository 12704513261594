import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective } from '@core/shared/util';

import { UploadEntryFileType } from '../../models';

@Component({
  selector: 'mpcm-file-type-tile',
  templateUrl: './file-type-tile.component.html',
  styleUrl: './file-type-tile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, IconDirective],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class FileTypeTileComponent {
  @HostBinding('class') class = 'mpcm-file-type-tile';

  @Input()
  fileType!: UploadEntryFileType;

  readonly fileTypeIconMap: Record<UploadEntryFileType, string> = {
    [UploadEntryFileType.MasterData]: '@database_outline',
    [UploadEntryFileType.PriceData]: 'local_offer',
    [UploadEntryFileType.ConsumptionData]: 'business_center',
    [UploadEntryFileType.SalesData]: 'euro',
    [UploadEntryFileType.KHEntgG21]: 'person',
    [UploadEntryFileType.Other]: 'note',
  };
}
