import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

import { FileInfo, UploadEntryFileType } from '../../models';
import { FileTypeNamePipe } from '../../pipes';
import { FileTypeTileComponent } from '../file-type-tile/file-type-tile.component';

@Component({
  selector: 'mpcm-file-info-dialog',
  templateUrl: './file-info-dialog.component.html',
  styleUrl: './file-info-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,

    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,

    FileTypeTileComponent,
    FileTypeNamePipe,
  ],
})
export class FileInfoDialogComponent {
  fileInfo: Partial<FileInfo> = {};

  readonly fileTypeOptions = [
    UploadEntryFileType.MasterData,
    UploadEntryFileType.PriceData,
    UploadEntryFileType.ConsumptionData,
    UploadEntryFileType.SalesData,
    UploadEntryFileType.KHEntgG21,
    UploadEntryFileType.Other,
  ] as const;

  isSelected(fileType: UploadEntryFileType): boolean {
    return this.fileInfo.fileType === fileType;
  }
}
