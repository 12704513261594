<mat-button-toggle-group
  name="purchaseRequisitionPeriod"
  [value]="selectedPeriod"
  [hideSingleSelectionIndicator]="true"
  (change)="onPeriodChange($event.value)"
>
  @for (option of periodOptions; track $index) {
    <mat-button-toggle [value]="option.value">
      {{ option.label }}
    </mat-button-toggle>
  }
</mat-button-toggle-group>
