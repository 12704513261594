import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { PageLink } from '@mp/shared/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';

import { CountUpStatComponent } from '../count-up-stat/count-up-stat.component';

interface ArticleStatistic {
  label: string;
  count: number;
}

@Component({
  selector: 'mpcm-article-counts-base-widget',
  templateUrl: './article-counts-base-widget.component.html',
  styleUrl: './article-counts-base-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DashboardWidgetComponent, CountUpStatComponent],
})
export class ArticleCountsBaseWidgetComponent {
  readonly title: InputSignal<string> = input.required<string>();
  readonly icon: InputSignal<string> = input.required<string>();
  readonly data: InputSignal<ArticleStatistic[]> = input.required<ArticleStatistic[]>();

  readonly featurePageLink: InputSignal<PageLink | undefined> = input<PageLink>();
  readonly enabledByPermission: InputSignal<boolean> = input<boolean>(false);
  readonly noPermissionText: InputSignal<string> = input<string>('');

  readonly searchTermChange: OutputEmitterRef<string> = output<string>();
  readonly featurePageNavigation: OutputEmitterRef<void> = output<void>();

  handleSearch(searchTerm: string): void {
    this.searchTermChange.emit(searchTerm);
  }

  onFeaturePageNavigation(): void {
    this.featurePageNavigation.emit();
  }
}
