import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { PurchaseRequisitionsPeriod } from '../../../models';

interface PurchaseRequisitionsPeriodOption {
  label: string;
  value: PurchaseRequisitionsPeriod;
}

@Component({
  selector: 'mpdm-requisitions-period-selector',
  standalone: true,
  templateUrl: './requisitions-period-selector.component.html',
  styleUrl: './requisitions-period-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonToggleModule],
})
export class RequisitionsPeriodSelectorComponent {
  @HostBinding('class') readonly class = 'mpdm-requisitions-period-selector';

  @Input()
  selectedPeriod!: PurchaseRequisitionsPeriod;

  @Output() readonly selectedPeriodChange: EventEmitter<PurchaseRequisitionsPeriod> =
    new EventEmitter<PurchaseRequisitionsPeriod>();

  readonly periodOptions: PurchaseRequisitionsPeriodOption[] = [
    { label: '1T', value: PurchaseRequisitionsPeriod.Today },
    { label: '7T', value: PurchaseRequisitionsPeriod.Last7Days },
    { label: '30T', value: PurchaseRequisitionsPeriod.Last30Days },
    {
      label: '90T',
      value: PurchaseRequisitionsPeriod.Last90Days,
    },
    { label: 'Alle', value: PurchaseRequisitionsPeriod.All },
  ];

  onPeriodChange(selectedPeriod: PurchaseRequisitionsPeriod): void {
    this.selectedPeriodChange.emit(selectedPeriod);
  }
}
