import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FeedbackEmailSettings } from '@mp/shared/helper-links/feedback/domain';

import { MailSenderDetails } from '../../models';

import { FeedbackMailToHrefPipe } from './feedback-mail-to-href.pipe';

@Component({
  selector: 'mp-feedback-mail-to-button',
  templateUrl: './feedback-mail-to-button.component.html',
  styleUrl: './feedback-mail-to-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIconModule, MatButtonModule, MatTooltipModule, FeedbackMailToHrefPipe],
})
export class FeedbackMailToButtonComponent {
  @Input({ required: true }) mailSenderDetails!: MailSenderDetails;

  @Input({ required: true }) feedbackEmailSettings!: FeedbackEmailSettings;

  @Input({ required: true }) isSmall!: boolean;
}
