import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { DatePipesModule } from '@core/shared/util';
import { UserBasic } from '@mp/shared/kernel/newsfeed/domain';

@Component({
  selector: 'mp-news-item-modification-info',
  templateUrl: './news-item-modification-info.component.html',
  styleUrl: './news-item-modification-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, DatePipesModule],
})
export class NewsItemModificationInfoComponent {
  @HostBinding() readonly class = 'mp-news-item-modification-info';

  @Input() modifierInfo!: UserBasic;

  @Input() modificationDate = '';
}
