import { AsyncPipe, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { Observable } from 'rxjs';

import { Pagination } from '@core/shared/domain';
import { PaginatorComponent, SpinnerComponent, UploadSchedulerService } from '@core/ui';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { UploadPermissions } from '@mpcm/feature';
import { LoginContextService } from '@mpk/shared/data-access';

import { UploadEntryStatus } from '../../models';
import { DatasourceUploadListItemActionsMenuComponent } from '../datasource-upload-list-item-actions-menu/datasource-upload-list-item-actions-menu.component';
import { DatasourceUploadListItemComponent } from '../datasource-upload-list-item/datasource-upload-list-item.component';
import { FileUploadHandlerComponent } from '../file-upload-handler/file-upload-handler.component';

import { DatasourceUploadSchedulerService } from './datasource-upload-scheduler.service';
import { DatasourceUploadWidgetViewModel } from './datasource-upload-widget-view-model';
import { DatasourceUploadWidgetStore } from './datasource-upload-widget.store';

@Component({
  selector: 'mpcm-datasource-upload-widget',
  templateUrl: './datasource-upload-widget.component.html',
  styleUrl: './datasource-upload-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SlicePipe,
    AsyncPipe,

    MatButtonModule,
    MatIconModule,
    MatListModule,

    FileUploadHandlerComponent,
    DatasourceUploadListItemComponent,
    DatasourceUploadListItemActionsMenuComponent,
    DashboardWidgetComponent,
    SpinnerComponent,
    PaginatorComponent,
  ],
  providers: [
    DatasourceUploadWidgetStore,
    { provide: UploadSchedulerService, useClass: DatasourceUploadSchedulerService },
  ],
})
export class DatasourceUploadWidgetComponent {
  @HostBinding('class') readonly class = 'mpcm-datasource-upload-widget';

  disabled = false;
  listExpanded = false;

  readonly vm$: Observable<DatasourceUploadWidgetViewModel> = this.store.vm$;

  readonly collapsedListLength = 3;
  readonly acceptedFileTypes: string = '.zip, .rar, .xlsx, .xslm, .xls, .csv, .xml';
  readonly maxFileBytes: number = 500 * 1024 * 1024;

  protected readonly hasManageUploadPermission: Signal<boolean> = this.loginContextService.permission(
    UploadPermissions.ManageUpload,
  );

  constructor(
    private readonly store: DatasourceUploadWidgetStore,
    private readonly uploadSchedulerService: UploadSchedulerService,
    private readonly loginContextService: LoginContextService,
  ) {
    this.store.fetchUploadedFiles();
  }

  toggleListExpand(): void {
    this.listExpanded = !this.listExpanded;
  }

  onPageChange(pageChangePagination: Pagination): void {
    this.store.fetchUploadedFiles(pageChangePagination);
  }

  onFileDelete(fileId: string): void {
    this.store.deleteUploadedFile(fileId);
  }

  onFileStatusChange(fileId: string, status: UploadEntryStatus): void {
    this.store.updateUploadedFileStatus({ fileId, status });
  }

  onFileAttach(fileId: string): void {
    this.uploadSchedulerService.triggerUpload({ fileId });
  }
}
