export interface FileInfo {
  fileType: UploadEntryFileType;
  comment?: string;
}

export enum UploadEntryFileType {
  MasterData = 'MasterData',
  PriceData = 'PriceData',
  ConsumptionData = 'ConsumptionData',
  SalesData = 'SalesData',
  KHEntgG21 = "KHEntgG21",
  Other = 'Other',
}
