import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/operators';
import { EMPTY, Observable, catchError, filter, first, forkJoin, map, merge, of, switchMap, tap } from 'rxjs';

import { NewsAssignedOrganisationsService, NewsfeedFacade } from '@mp/shared/kernel/newsfeed/data-access';
import { CreateNewsItem, NewsItem, OrganisationBasic, UpdateNewsItem } from '@mp/shared/kernel/newsfeed/domain';
import { LoginContextService } from '@mpk/shared/data-access';

import { NewsPermissions } from '../../permissions';

import { NewsItemEditContainerViewModel } from './news-item-edit-container-view-model';

export interface NewsItemEditState {
  newsItemId: string | undefined;
  showOrganisationsSelector: boolean;
  selectedOrganisations: OrganisationBasic[];
}

export const INITIAL_STATE: NewsItemEditState = {
  newsItemId: undefined,
  showOrganisationsSelector: false,
  selectedOrganisations: [],
};

@Injectable()
export class NewsItemEditContainerStore extends ComponentStore<NewsItemEditState> {
  private readonly hasCreateNewsForAllOrganizationsPermission = this.loginContextService.permission(
    NewsPermissions.CreateNewsForAllOrganizations,
  );

  private readonly activeOrganization$ = toObservable(this.loginContextService.organizationContext);

  constructor(
    private readonly newsfeedFacade: NewsfeedFacade,
    private readonly loginContextService: LoginContextService,
    private readonly newsAssignedOrganisationsService: NewsAssignedOrganisationsService,
  ) {
    super(INITIAL_STATE);

    this.initAvailableOrganisationsEffect();
  }

  readonly showOrganisationsSelector$: Observable<boolean> = this.select((state) => state.showOrganisationsSelector);

  readonly selectedOrganisations$: Observable<OrganisationBasic[]> = this.select(
    (state) => state.selectedOrganisations,
  );

  readonly availableOrganisations$: Observable<OrganisationBasic[]> = this.newsfeedFacade.availableOrganisations$;

  private readonly createNewsSuccess$: Observable<NewsItem> = this.newsfeedFacade.createNewsSuccess$.pipe(
    filter(() => !this.get().newsItemId),
  );

  private readonly updateNewsSuccess$: Observable<NewsItem> = this.newsfeedFacade.updateNewsSuccess$.pipe(
    filter(({ newsItemId }) => newsItemId === this.get().newsItemId),
  );

  readonly saveNewsSuccess$: Observable<NewsItem> = merge(this.createNewsSuccess$, this.updateNewsSuccess$);

  readonly vm$: Observable<NewsItemEditContainerViewModel> = this.select(
    this.showOrganisationsSelector$,
    this.selectedOrganisations$,
    this.availableOrganisations$,
    (showOrganisationsSelector, selectedOrganisations, availableOrganisations) => ({
      showOrganisationsSelector,
      selectedOrganisations,
      availableOrganisations,
    }),
  );

  readonly setNewsItemIdContext = this.effect(
    (newsItemId$: Observable<string | undefined>): Observable<OrganisationBasic[]> => {
      return newsItemId$.pipe(
        tap((newsItemId) => this.updateNewsItemId(newsItemId)),
        switchMap((newsItemId) =>
          this.getOrganisationsAssignedToNews(newsItemId).pipe(
            tap((selectedOrganisations) => this.updateSelectedOrganisations(selectedOrganisations)),
            catchError(() => EMPTY),
          ),
        ),
      );
    },
  );

  private readonly updateNewsItemId = this.updater(
    (state, newsItemId: string | undefined): NewsItemEditState => ({
      ...state,
      newsItemId,
    }),
  );

  private readonly updateSelectedOrganisations = this.updater(
    (state, selectedOrganisations: OrganisationBasic[]): NewsItemEditState => ({
      ...state,
      selectedOrganisations,
    }),
  );

  private readonly updateShowOrganisationsSelector = this.updater(
    (state, showOrganisationsSelector: boolean): NewsItemEditState => ({
      ...state,
      showOrganisationsSelector,
    }),
  );

  createNewsEntry(createNewsItem: CreateNewsItem): void {
    this.newsfeedFacade.createNewsEntry(createNewsItem);
  }

  updateNewsEntry(newsItemId: string, updateNewsItem: UpdateNewsItem): void {
    this.newsfeedFacade.updateNewsEntry(newsItemId, updateNewsItem);
  }

  deleteNewsEntry(newsItemId: string): void {
    this.newsfeedFacade.deleteNewsEntry(newsItemId);
  }

  private getOrganisationsAssignedToNews(newsItemId: string | undefined): Observable<OrganisationBasic[]> {
    const organisationsAssignedToNews$: Observable<OrganisationBasic[]> = newsItemId
      ? this.newsAssignedOrganisationsService.getOrganisationsAssignedToNews(newsItemId)
      : of([]); // In case of a new news item no organisations are assigned yet

    return forkJoin([organisationsAssignedToNews$, this.getProfileActiveOrganisation()]).pipe(
      map(([selectedOrganisations, activeOrganisation]) =>
        this.handleActiveOrganisationInList(selectedOrganisations, activeOrganisation),
      ),
    );
  }

  private handleActiveOrganisationInList(
    selectedOrganisations: OrganisationBasic[],
    activeOrganisation: OrganisationBasic | undefined,
  ): OrganisationBasic[] {
    if (!activeOrganisation || this.isActiveOrganisationSelected(selectedOrganisations, activeOrganisation)) {
      return selectedOrganisations;
    }

    // In case active organisation is missing, add it to the list since it is required
    return [activeOrganisation, ...selectedOrganisations];
  }

  private isActiveOrganisationSelected(
    organisationsList: OrganisationBasic[],
    { organizationId: activeOrganisationId }: OrganisationBasic,
  ): boolean {
    return organisationsList.some(({ organizationId }) => organizationId === activeOrganisationId);
  }

  private getProfileActiveOrganisation(): Observable<OrganisationBasic | undefined> {
    return this.activeOrganization$.pipe(
      map(
        (activeOrganisation) =>
          activeOrganisation && { organizationId: activeOrganisation.organizationId, name: activeOrganisation.name },
      ),
      first(),
    );
  }

  private initAvailableOrganisationsEffect() {
    const hasMultiOrganizationNewsWriteRight$: Observable<boolean> = toObservable(
      this.hasCreateNewsForAllOrganizationsPermission,
    );

    this.effect((hasRight$: Observable<boolean>) =>
      hasRight$.pipe(
        tap((hasRight) => this.updateShowOrganisationsSelector(hasRight)),
        filter(Boolean),
        concatLatestFrom(() => this.newsfeedFacade.availableOrganisations$),
        filter(([, availableOrganisations]) => availableOrganisations.length === 0),
        // Fetch available organisations if they are not fetched yet
        tap(() => this.newsfeedFacade.fetchAvailableOrganisations()),
      ),
    )(hasMultiOrganizationNewsWriteRight$);
  }
}
