import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({ name: 'mpNewsItemDescriptionErrorMessage' })
export class NewsItemDescriptionErrorMessagePipe implements PipeTransform {
  transform(errors: ValidationErrors): string {
    // Required (without markups) Editor's built-in validator (as empty text will result in <p></p>)
    if (errors['requiredError']) {
      return 'Bitte die Nachricht angeben.';
    }
    // Text content (raw text, without markups) Editor's built-in validator
    if (errors['maxLengthError']) {
      return 'Der Inhalt der Nachricht ist zu lang.';
    }
    // Content with markups
    if (errors['maxlength']) {
      return 'Der Inhalt der Nachricht ist zu lang.';
    }

    return '';
  }
}
