import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Params, RouterLink } from '@angular/router';

import { CountUpDirective, CountUpOptions } from '@core/shared/util';
import { PageLink } from '@mp/shared/data-access';

@Component({
  selector: 'mpcm-count-up-stat',
  templateUrl: './count-up-stat.component.html',
  styleUrl: './count-up-stat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, MatIconModule, CountUpDirective],
})
export class CountUpStatComponent {
  @HostBinding('class') readonly class = 'mpcm-count-up-stat';

  readonly statValue: InputSignal<number> = input<number>(0);

  readonly statLabel: InputSignal<string> = input<string>('');

  readonly link: InputSignal<PageLink | undefined> = input<PageLink>();

  readonly queryParams: InputSignal<Params | undefined> = input<Params>();

  readonly options: InputSignal<CountUpOptions> = input<CountUpOptions>(CountUpOptions.Numeric);

  readonly statLinkClick: OutputEmitterRef<void> = output<void>();

  onStatLinkClick(): void {
    this.statLinkClick.emit();
  }
}
