import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { MyOrganizationWidgetData } from '@mp/dashboard/data-access';
import { DashboardWidgetComponent } from '@mp/shared/ui/dashboard-widget';
import { CountUpStatComponent } from '@mpcm/shared';

@Component({
  selector: 'mp-my-organization-widget',
  templateUrl: './my-organization-widget.component.html',
  styleUrl: './my-organization-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DashboardWidgetComponent, CountUpStatComponent],
})
export class MyOrganizationWidgetComponent {
  readonly data: InputSignal<MyOrganizationWidgetData | undefined> = input.required<
    MyOrganizationWidgetData | undefined
  >();
}
